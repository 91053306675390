























import Vue from 'vue';
import Component from 'vue-class-component';
import ProjectConfigurations from '@/pages/settings/ProjectConfigurations.vue';
import UserConfigurations from '@/pages/settings/UserConfigurations.vue';
import { User, Global } from '@/store';

@Component({
  components: {
    ProjectConfigurations,
    UserConfigurations
  }
})
export default class GeneralConfigurations extends Vue {

    @User.State('project') project;
    @Global.Mutation('setPageTitle') setPageTitle;

    tab = 'user-settings';

    mounted(){
        this.setPageTitle('General Settings');
    }

    setTabItem(tab) {
      this.tab = tab;
    }

}
