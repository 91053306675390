




import Vue from 'vue';
import Component from 'vue-class-component';
import GeneralConfigurations from '@/views/GeneralConfigurations.vue';

@Component({
    components: {
        GeneralConfigurations
    }
})
export default class GeneralConfigAllProjects extends Vue {

}
